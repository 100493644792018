import {
  getAppointments as fetchAppointment,
  statusUpdate as fireUpdateStatus,
  createAppointment as fireCreateAppointment,
} from "../store/reducer/AppoitmentsReducer";

import { store } from "../store/Store";

export class AppoitmentService {
  constructor() {
    this.dispatch = store.dispatch;
  }
  getAppointments() {
    this.dispatch(fetchAppointment());
  }
  updaStatus(id, status) {
    this.dispatch(fireUpdateStatus({ id: id, status: status }));
  }
  createAppointment(id, startDate, endDate) {
    this.dispatch(
      fireCreateAppointment({
        id: id,
        startDate: startDate,
        endDate: endDate,
      })
    );
  }
}
