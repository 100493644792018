import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { AppoitmentService } from "../../services/Appointment";
import { generalCheckbox } from "./Forms";

const AppointmentModal = (props) => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [dateValue, setDateValue] = useState(new Date());
	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);
	const appointmentService = new AppoitmentService();
	const [formatedDate, setFormatedDate] = useState(null)
	const start = `${formatedDate} ${startTime}:00`;
	const end = `${formatedDate} ${endTime}:00`;
	const changeTime = (time) => {
		let timeStamp = time.split("-");
		setStartTime(timeStamp[0]);
		setEndTime(timeStamp[1]);
	};

	const dateFormat = (e) => {
		let date = ("0" + e.getDate()).slice(-2)
		let month = ("0"+e.getMonth()+1).slice(-2)
		let year = e.getFullYear()
		setFormatedDate(`${year}-${month}-${date}`)
		setDateValue(e)
	};

	return (
		<div id="appointUpdate">
			<Button variant="primary" onClick={handleShow}>
				Edit
			</Button>
			<Modal show={show} onHide={handleClose} className="cardWrapper">
				<div className="customRow">
					<div className="datePick">
						<p className="select">Select Date</p>
						<Calendar
							onChange={(e) => dateFormat(e)}
							value={dateValue}
						/>
					</div>
					<div className="timePick">
						<p className="select">Select Time</p>
						<div className="timeSlotWrapper">
							<div className="timeSlot">
								{generalCheckbox(
									"09:00-09:30",
									"09:00-09:30 am",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"09:30-10:00",
									"09:30-10:00 am",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"10:00-10:30",
									"10:00-10:30 am",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"10:30-11:00",
									"10:30-11:00 am",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"11:00-11:30",
									"11:00-11:30 am",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"11:30-12:00",
									"11:30-12:00 am",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"12:00-12:30",
									"12:00-12:30 pm",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"12:30-13:00",
									"12:30-1:00 pm",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"13:00-13:30",
									"1:00-1:30 pm",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"13:30-14:00",
									"1:30-2:00 pm",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"14:00-14:30",
									"2:00-2:30 pm",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"14:30-15:00",
									"2:30-3:00 pm",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"15:00-15:30",
									"3:00-3:30 pm",
									changeTime
								)}
							</div>
							<div className="timeSlot">
								{generalCheckbox(
									"15:30-16:00",
									"3:30-4:00 pm",
									changeTime
								)}
							</div>
						</div>
					</div>
				</div>
				<Button
					variant="success mt-5 mb-5 pr-5 pl-5"
					onClick={() =>
						appointmentService.createAppointment(
							props.id,
							start,
							end
						)
					}
				>
					Update Schedule
				</Button>
			</Modal>
		</div>
	);
};

export default AppointmentModal;
