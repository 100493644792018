import React from "react";
import { Table, Button } from "react-bootstrap";
import formatDate from "../../helpers/dateFormate";
import { RoadConstructionService } from "../../services/RoadConstructionService";
import ApplicationDetailsModal from "../modals/ApplicationDetailsModal";

function RoadConstructionTable(props) {
  const roadConstructionService = new RoadConstructionService();
  return (
    <div className="tableWrapper p-4">
      <p className="tableTitle">Road Construction List</p>
      <Table responsive>
        <thead
          className="headerWraper text-center"
          style={{
            borderBottom: "2px solid #888",
            padding: "10px 0",
          }}
        >
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Date of Apllication</th>
            <th>Work Status</th>
            <th>Status</th>
            <th>Accept</th>
            <th>Decline</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {props.info &&
            props.info.map((item) => {
              return (
                <tr>
                  <td>{item.id}</td>
                  <td>{item.applicant_name}</td>
                  <td>{item.created_at}</td>
                  <td>{item.work_status}</td>
                  <td>{item.status}</td>
                  {item.status === "Pending" ? (
                    <td>
                      <Button
                        variant="success"
                        onClick={() =>
                          roadConstructionService.updateStatus(
                            item.id,
                            "Accepted"
                          )
                        }
                      >
                        Accept
                      </Button>
                    </td>
                  ) : item.status === "Accepted" ||
                    item.status === "Declined" ? (
                    <td>
                      <ApplicationDetailsModal id={item.id} />
                    </td>
                  ) : (
                    "--"
                  )}
                  {item.status === "Pending" ? (
                    <td>
                      <Button
                        variant="danger"
                        onClick={() =>
                          roadConstructionService.updateStatus(
                            item.id,
                            "Declined"
                          )
                        }
                      >
                        Decline
                      </Button>
                    </td>
                  ) : (
                    "--"
                  )}
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default RoadConstructionTable;
