import { configureStore } from "@reduxjs/toolkit";
import appointments from "./reducer/AppoitmentsReducer";
import auth from "./reducer/AuthReducer";
import forgotPassword from "./reducer/ForgotPasswordReducer";
import roadConstruction from "./reducer/RoadConstructionReducer";
import news from "./reducer/NewsReducer";
import library from "./reducer/LibraryReducer";
import applications from './reducer/ApplicationReducer'

export const store = configureStore({
  reducer: {
    auth: auth,
    forgotPassword: forgotPassword,
    appointments: appointments,
    roadConstruction: roadConstruction,
    news: news,
    library: library,
    applications: applications,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
