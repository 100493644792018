import React from "react";
import { Link } from "react-router-dom";
import Allowance from "../options/allowance";
import Application from "../options/application";
import Complain from "../options/complain";
import Education from "../options/education";
import Medical from "../options/medical";
import News from '../options/news'
import Library from '../options/library'

const Sidebar = () => {
	return (
    <div className="sidebarWrapper">
      <div className="sideBarTitle">MENU</div>
      <Application />
      <Complain />
      <Allowance />
      <Medical />
      <Education />
      <Link
        to={{
          pathname: "/appointments",
        }}
      >
        <p className="optionTitle">
          <span className="iconGap">
            <img
              className="iconSize"
              src="/images/appointment.svg"
              alt="application"
            />
          </span>
          Appointment
        </p>
      </Link>
      <News />
      <Library />
    </div>
  );
};

export default Sidebar;
