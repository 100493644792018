import React, { useEffect } from "react";
import Layout from "../../layout";
import { NewsService } from "../../services/NewsService";
import { useSelector } from "react-redux";

const NewsPage = () => {
  const news = useSelector((state) => state.news.news);
  useEffect(() => {
    const newsService = new NewsService();
    newsService.getNews();
  }, []);

//in your component
 const addDefaultSrc = (e) =>{
  e.target.src = './images/default.png'
}

  return (
    <Layout>
      <div className="p-5 shadow rounded-md border border-gray-100 w-[70%] h-[70%] ml-[4%] mt-[3%]">
        <p className="text-gray-500 text-3xl">News List</p>
        {news && (
          <table class="table-fixed w-full text-left text-gray-500 dark:text-gray-400">
            <thead className="border-b border-t border-gray-900">
              <tr>
                <th className="text-gray-900 p-2">Thumb</th>
                <th className="text-gray-900 p-2">Title</th>
                <th className="text-gray-900 p-2">Created</th>
                <th className="text-gray-900 p-2">Details</th>
                <th className="text-gray-900 p-2">Views</th>
                <th className="text-gray-900 p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {news.map((news) => {
                return (
                  <tr className="mb-3" key={news.id}>
                    <td className="text-gray-500 p-2">
                      <img
                        onError={(e) => addDefaultSrc(e)}
                        src={news.thumb}
                        alt="thumb"
                        className="w-16 h-16 rounded-md border rounded-md"
                      />
                    </td>
                    <td className="text-gray-500 p-2">{news.title}</td>
                    <td className="text-gray-500 p-2">{news.created_at}</td>
                    <td className="text-gray-500 p-2 line-clamp-3 h-[80px]">
                      {news.details}
                    </td>
                    <td className="text-gray-500 p-2">{news.views}</td>
                    <td className="text-gray-500 p-2">
                      <button className="py-2 px-4 bg-green-500 text-white rounded-md">
                        Edit
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Layout>
  );
};

export default NewsPage;
