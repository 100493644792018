import React from "react";
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Font
} from "@react-pdf/renderer";
import bengaliFont from "./fonts/kalpurush.ttf";

// Register the Bengali font
Font.register({
	family: "BengaliFont",
	src: bengaliFont // Replace with the correct font file name and extension
});

const styles = StyleSheet.create({
	page: {
		flexDirection: "row",
		backgroundColor: "#E4E4E4"
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1
	},
	section2: {
		display: "flex",
		justifyContent: "between"
	},
	title: {
		fontSize: 24,
		marginHorizontal: "auto",
		paddingBottom: 30,
		fontFamily: "BengaliFont"
	},
	content: {
		fontSize: 12,
		paddingVertical: 15,
		fontFamily: "BengaliFont"
	},
	rowContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 10
	},
	value: {
		width: "48%", // Adjust the width as per your requirement
		fontSize: 12,
		fontFamily: "BengaliFont"
	}
});

const MyPDF = ({ details }) => {
	const filteredData =
		details && Object?.entries(details)?.filter(([key]) => key !== "details");
	const translations = {
		application_sub_type: "আবেদন উপ-ধরন",
		application_type: "আবেদনের ধরণ",
		created_at: "তৈরি করা হয়েছে",
		details: "বিশদ",
		district: "জেলা",
		email: "ইমেল",
		id: "আইডি",
		mobile: "মোবাইল",
		name: "নাম",
		permanent_address: "স্থায়ী ঠিকানা",
		police_station: "থানা",
		present_address: "বর্তমান ঠিকানা",
		status: "আবেদনের অবস্থা",
		sub_district: "উপজেলা",
		union: "ইউনিয়ন",
		village: "গ্রাম",
		ward: "ওয়ার্ড"
	};

	const app_type_translations = {
		old_age: "বৃদ্ধ ভাতা",
		maternity: "মাতৃকালীন ভাতা",
		disabled_person: "প্রতিদ্বন্দ্বী ভাতা",
		widow: "বিধবা ভাতা",
		medical_assistance: "চিকিৎসা সহায়তা",
		stipend: "উপবৃতি ভাতা",
		school_development: "স্কুল উন্নয়ন",
		college_development: "কলেজ উন্নয়ন",
		madrasa_development: "মাদ্রাসা উন্নয়ন",
		road_development: "রাস্তা উন্নয়ন",
		electricity: "বিদ্যুৎ উন্নয়ন",
		solar: "সোলার উন্নয়ন",
		mosque_development: "মসজিদ উন্নয়ন",
		eidgah_development: "ঈদগাহ উন্নয়ন",
		temple_development: "মন্দির উন্নয়ন",
		orphanage_development: "এতিমখানা উন্নয়ন",
		tube_well_installation: "টিউব অয়েল নির্মাণ",
		culvert_construction: "কালভার্ট নির্মাণ",
		pool_construction: "পুল নির্মাণ",
		default: "সাধারণ আবেদন",
		general_interview: "সাধারণ আবেদন"
	};

	const banglaNumbers = {
		0: "০",
		1: "১",
		2: "২",
		3: "৩",
		4: "৪",
		5: "৫",
		6: "৬",
		7: "৭",
		8: "৮",
		9: "৯",
		10: "১০",
		11: "১১",
		12: "১২",
		13: "১৩",
		14: "১৪",
		15: "১৫",
		16: "১৬",
		17: "১৭",
		18: "১৮",
		19: "১৯",
		20: "২০"
	};

	const renderedValues = [];

	for (let i = 0; i < filteredData?.length; i += 2) {
		console.log(i);
		const [key1, value1] = filteredData[i] || [];
		const [key2, value2] = filteredData[i + 1] || [];

		renderedValues.push(
			<View key={`${key1}-${key2}`} style={styles.rowContainer}>
				<Text style={styles.value}>
					{banglaNumbers[i === 0 ? 2 : i + 2]}| {translations[key1] || key1}:{" "}
					{value1}
				</Text>
				{key2 && (
					<Text style={styles.value}>
						{banglaNumbers[i === 0 ? 3 : i + 3]}| {translations[key2] || key2}:{" "}
						{value2}
					</Text>
				)}
			</View>
		);
	}

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<View style={styles.section}>
					<Text style={styles.title}>
						{app_type_translations[details?.application_sub_type]}
					</Text>
					<Text style={styles.content}>
						আবেদন আইডি: {details.id}                                                   তারিখ: {details.created_at}
					</Text>
					<Text style={styles.content}>আবেদনের বর্ণনা:</Text>
					<Text style={styles.content}>{details?.details}</Text>
					<Text style={styles.content}>
						{banglaNumbers[1]}| আবেদনকারীর নাম: {details?.name}
					</Text>
					{renderedValues}


				</View>
			</Page>
		</Document>
	);
};

export default MyPDF;
