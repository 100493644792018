import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  books: null,
  activeId: null,
  bookDetails: null,
};

export const getBooks = createAsyncThunk(
  "library/getBooks",
  async (appointment, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/v1/books",
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBookDetails = createAsyncThunk(
  "library/getBookDetails",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/v1/books/" + id,
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createBooks = createAsyncThunk(
  "news/createBooks",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/v1/books/create`,
        data,
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const library = createSlice({
  name: "library",
  initialState,
  reducers: {
    addActiveId: (state, payload) => {
      state.activeId = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBooks.fulfilled, (state, action) => {
        state.books = action.payload.books;
      })
      .addCase(getBooks.rejected, (state, action) => {
        console.log("Booklist get REJECTED");
      })
      .addCase(getBookDetails.fulfilled, (state, action) => {
        state.bookDetails = action.payload.book;
      })
      .addCase(getBookDetails.rejected, (state, action) => {
        console.log("Book details get REJECTED");
      })
      .addCase(createBooks.fulfilled, (state, action) => {
        window.location.reload();
      })
      .addCase(createBooks.rejected, (state, action) => {
        console.log("NEWS POST REJECTED");
      });
  },
});

export const { addActiveId } = library.actions;

export default library.reducer;
