import React, { useEffect } from "react";
import Layout from "../../layout";
import { AppoitmentService } from "../../services/Appointment";
import { useSelector } from "react-redux";
import RoadConstructionTable from "../../components/tables/RoadConstructionTable";
import { RoadConstructionService } from "../../services/RoadConstructionService";

const RoadConstruction = () => {
  const applications = useSelector(
    (state) => state.roadConstruction.applications
  );
  useEffect(() => {
    const roadConstructionService = new RoadConstructionService();
    roadConstructionService.getRoadConstructionApplication();
  }, []);

  const data = [
    {
      id: 2,
      applicant_name: "kuddus",
      created_at: "27-06-2021",
      work_status: "Pending",
      status: "Accepted",
    },
    {
      id: 3,
      applicant_name: "kuddus",
      created_at: "27-06-2021",
      work_status: "Pending",
      status: "Accepted",
    },
  ];

  return <Layout>{data && <RoadConstructionTable info={data} />}</Layout>;
};

export default RoadConstruction;
