/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { addActiveId } from "../../store/reducer/LibraryReducer";
import { useDispatch } from "react-redux";

const Modal = (props) => {
  const bookInfo = useSelector((state) => state.library.bookDetails);
  const [bookDetails, setBookDetails] = useState(null);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch()

  const addDefaultSrc = (e) => {
    e.target.src = "./images/default.png";
  };

  useEffect(() => {
    setBookDetails(bookInfo);
  }, [bookInfo]);

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(addActiveId(null))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-2 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                {bookDetails && (
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900 mb-3"
                      >
                        {bookDetails.name}
                      </Dialog.Title>
                      <div className="mt-2 flex items-center w-full">
                        <div className="mr-2">
                          <img
                            onError={(e) => addDefaultSrc(e)}
                            src={""}
                            alt="thumb"
                            className="w-24 h-24 rounded-md border rounded-md mb-4"
                          />
                        </div>

                        <div className="flex flex-col justify-start items-start gap-2">
                          <p className="text-gray-500 text-sm mb-2">
                            <b>Writer:</b> {bookDetails.writer}
                          </p>
                          <p className="text-gray-500 text-sm mb-2">
                            <b>Publisher:</b> {bookDetails.publisher}
                          </p>
                          <p className="text-gray-500 text-sm">
                            <b>Rating:</b> {bookDetails.rating}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
