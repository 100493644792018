import React from "react";
import { Dropdown } from "react-bootstrap";

function UserDropdown() {
	const logout = () =>{
		localStorage.removeItem("amar-sheba-token")
		window.location.reload();
	}
	return (
		<Dropdown>
			<Dropdown.Toggle id="dropdown" className="userDropdown mt-3">
				<img src="/images/example.svg" className="userPic" alt="logo" />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item>Action</Dropdown.Item>
				<Dropdown.Item>Another action</Dropdown.Item>
				<Dropdown.Item onClick={()=> logout()}>Logout</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
}

export default UserDropdown;
