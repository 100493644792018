import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  applications: null,
  applciationApiCalling: false,
  filterApiLoading: false,
};

export const getApplications = createAsyncThunk(
  "applications/getApplications",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/v1/applications",
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getApplicationsFiltered = createAsyncThunk(
  "applications/getApplicationsFiltered",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const params = {
      application_type: data.application_type,
      application_sub_type: data.application_sub_type,
    };

    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/v1/applications",
        { params, headers }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getApplicationsDetails = createAsyncThunk(
  "applications/getApplicationsDetails",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/v1/applications/" + data,
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const applicationUpdate = createAsyncThunk(
  "applications/applicationUpdate",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/v1/applications/${data.id}/status`,
        { status: data.status },
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const applications = createSlice({
  name: "applications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getApplications.fulfilled, (state, action) => {
        state.applications = action.payload.applications;
        state.applciationApiCalling = false;
      })
      .addCase(getApplications.rejected, (state, action) => {
        console.log("Application Fecthing Rejected");
        state.applciationApiCalling = false;
      })
      .addCase(getApplications.pending, (state, action) => {
        state.applciationApiCalling = true;
        // window.location.reload();
      })
      .addCase(getApplicationsFiltered.fulfilled, (state, action) => {
        state.applications = action.payload.applications;
        state.filterApiLoading = false;
      })
      .addCase(getApplicationsFiltered.rejected, (state, action) => {
        console.log("Application Fecthing Rejected");
        state.filterApiLoading = false;
      })
      .addCase(getApplicationsFiltered.pending, (state, action) => {
        state.filterApiLoading = true;
        // window.location.reload();
      })
      .addCase(getApplicationsDetails.fulfilled, (state, action) => {
        // state.applications = action.payload.applications;
      })
      .addCase(getApplicationsDetails.rejected, (state, action) => {})
      .addCase(getApplicationsDetails.pending, (state, action) => {
        // window.location.reload();
      })
      .addCase(applicationUpdate.fulfilled, (state, action) => {
        // state.applications = action.payload.applications;
        window.location.reload();
      })
      .addCase(applicationUpdate.rejected, (state, action) => {})
      .addCase(applicationUpdate.pending, (state, action) => {
        // window.location.reload();
      });
  },
});

export const selectApplicationLoading = (state) =>
  state.applications.applciationApiCalling;
export const selecFilterLoading = (state) =>
  state.applications.filterApiLoading;
export const selectApplications = (state) => state.applications.applications;

export default applications.reducer;
