import React, { useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import ApplicationDetailsModal from "../modals/ApplicationDetailsModal";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationUpdate,
  selecFilterLoading,
} from "../../store/reducer/ApplicationReducer";
import Pagination from "../Pagination";
import FilterDropdown from "../FilterDropdown";
import { TailSpin } from "react-loader-spinner";

const GeneralApplicationTable = (props) => {
  const dispatch = useDispatch();
  const filterLoading = useSelector(selecFilterLoading);
  const [propsInfo, setPropsInfo] = React.useState(props.info);
  const perPageData = 10;
  const [sliceData, setSliceData] = React.useState({
    limit: perPageData,
    offset: 0,
  });

  const nextPage = () => {
    // @ts-ignore
    if (sliceData.limit >= propsInfo?.length) return;

    setSliceData({
      ...sliceData,
      limit: sliceData.limit + perPageData,
      offset: sliceData.offset + perPageData,
    });
  };

  const prevPage = () => {
    setSliceData({
      ...sliceData,
      limit: sliceData.limit - perPageData,
      offset: sliceData.offset - perPageData,
    });
  };

  const handleClick = (data) => {
    try {
      const token = localStorage.getItem("amar-sheba-token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = axios.post(
        process.env.REACT_APP_API_URL + `/v1/applications/${data.id}/status`,
        { status: data.status },
        config
      );

      updateInfos(data);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  };

  const updateInfos = (data) => {
    let newAllData = propsInfo.map((item) => {
      let newItem = {...item};
      if (item.id == data.id) {
        newItem.status = data.status;
      }
      return newItem;
    });
    setPropsInfo(newAllData);
  }

  return (
    <div className="tableWrapper p-4">
      <div className="py-3">
        <p>আবেদন ফিল্টার করুন</p>
        <FilterDropdown />
      </div>
      <p className="tableTitle">General Application List</p>

      {!filterLoading && (
        <>
          <Table responsive>
            <thead
              className="headerWraper text-center"
              style={{
                borderBottom: "2px solid #888",
                padding: "10px 0",
              }}
            >
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Type</th>
                <th>Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {propsInfo &&
                propsInfo
                  .slice(sliceData.offset, sliceData.limit)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.application_type}</td>
                        <td>{item.created_at}</td>
                        <td>{item.status}</td>
                        {item.status === "pending" ? (
                          <td>
                            <Button
                              variant="success"
                              onClick={() => handleClick({
                                id: item.id,
                                status: "accepted",
                              })}
                            >
                              Accept
                            </Button>
                          </td>
                        ) : item.status === "accepted" ||
                          item.status === "declined" ? (
                          <td>
                            <ApplicationDetailsModal id={item.id} />
                          </td>
                        ) : null}
                        {item.status === "pending" ? (
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => {
                                dispatch(
                                  applicationUpdate({
                                    id: item.id,
                                    status: "declined",
                                  })
                                );
                              }}
                            >
                              Decline
                            </Button>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
          <Pagination
            limit={
              sliceData.limit >= propsInfo?.length
                ? propsInfo?.length
                : sliceData.limit
            }
            offset={sliceData.offset}
            total={propsInfo?.length}
            disablePrev={
              !propsInfo || propsInfo?.length === 0 || sliceData.offset === 0
            }
            disableNext={sliceData.limit >= propsInfo?.length}
            next={() => nextPage()}
            prev={() => prevPage()}
          />
        </>
      )}
      {filterLoading && (
        <div className="w-full flex justify-center mt-10">
          <TailSpin type="Rings" color="#00BFFF" height={60} width={60} />
        </div>
      )}
    </div>
  );
};

export default GeneralApplicationTable;
