import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  appointments: null,
};

export const getAppointments = createAsyncThunk(
  "appointments/getAppointments",
  async (appointment, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/v1/applications/appointments",
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const statusUpdate = createAsyncThunk(
  "appointments/statusUpdate",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          `/v1/applications/appointments/${data.id}/status-update`,
        { status: data.status },
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAppointment = createAsyncThunk(
  "appointments/createAppointment",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const date = {
      start: data.startDate,
      end: data.endDate,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          `/v1/applications/appointments/${data.id}/update`,
        date,
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const appointments = createSlice({
  name: "appointments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload.appointments;
      })
      .addCase(getAppointments.rejected, (state, action) => {
        console.log("APPOINTMENT REJECTED");
      })
      .addCase(statusUpdate.fulfilled, (state, action) => {
        window.location.reload();
      })
      .addCase(statusUpdate.rejected, (state, action) => {
        console.log("STATUS UPDATE REJECTED");
      })
      .addCase(createAppointment.fulfilled, (state, action) => {
        window.location.reload();
      })
      .addCase(createAppointment.rejected, (state, action) => {
        console.log("APPOINTMENT UPDATE REJECTED");
      });
  },
});

// export const { clearAuthError } = authReducer.actions

export default appointments.reducer;
