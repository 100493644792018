import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Cell,
  Legend,
  PieChart,
  Pie,
  Sector,
  Label,
  LabelList,
} from "recharts";
import StatisticsCard from "../dashboard/StatisticsCard";

const DashboardContentArea = () => {
  const data = [
    {
      name: "1/05/2022",
      uv: 40,
      pv: 24,
      amt: 2400,
    },
    {
      name: "3/05/2022",
      uv: 30,
      pv: 13,
      amt: 2210,
    },
    {
      name: "5/05/2022",
      uv: 20,
      pv: 98,
      amt: 2290,
    },
    {
      name: "7/05/2022",
      uv: 27,
      pv: 39,
      amt: 2000,
    },
    {
      name: "9/05/2022",
      uv: 18,
      pv: 48,
      amt: 2181,
    },
    {
      name: "11/05/2022",
      uv: 23,
      pv: 38,
      amt: 2500,
    },
    {
      name: "13/05/2022",
      uv: 34,
      pv: 43,
      amt: 2100,
    },
  ];

  const pieData = [
    { name: "Daaka", value: 400},
    { name: "Chittagong", value: 300 },
    { name: "Mymensingh", value: 300 },
    { name: "Rajshahi", value: 200 },
  ];

  const barData = [
    {
      name: "Dhaka",
      uv: 40,
      pv: 24,
      amt: 2400,
    },
    {
      name: "Chittagong",
      uv: 30,
      pv: 13,
      amt: 2210,
    },
    {
      name: "Rajshahi",
      uv: 20,
      pv: 98,
      amt: 2290,
    },
    {
      name: "Mymensingh",
      uv: 27,
      pv: 39,
      amt: 2000,
    },
    {
      name: "Dinajpur",
      uv: 18,
      pv: 48,
      amt: 2181,
    }
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <div className="w-[100%]">
      <div className="">
        <div className="p-5 flex gap-3">
          <StatisticsCard
            color={"bg-blue-800"}
            data={1234}
            icon={"user"}
            title={"Total User"}
          />
          <StatisticsCard
            color={"bg-green-500"}
            data={123}
            icon={"applications"}
            title={"Applications"}
          />
          <StatisticsCard
            color={"bg-red-500"}
            data={345}
            icon={"complaints"}
            title={"Complaints"}
          />
          <StatisticsCard
            color={"bg-yellow-500"}
            data={654}
            icon={"appointments"}
            title={"Appoitments"}
          />
        </div>
      </div>

      <div>
        <div className="p-5 flex flex-wrap gap-3">
          <div>
            <div className="shadow rouded-md p-4">
              <p className="text-gray-800 text-lg mb-2 font-semibold">
                Number of Applications
              </p>
              <p className="text-gray-800 text-lg mb-4 font-semibold">123</p>
              <AreaChart
                width={300}
                height={200}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
              </AreaChart>
            </div>
          </div>

          <div>
            <div className="shadow rouded-md p-4">
              <p className="text-gray-800 text-lg mb-4 font-semibold">
                Most Used Area
              </p>
              <BarChart
                width={300}
                height={235}
                data={barData}
                margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis
                  label={{
                    value: "used number",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Bar dataKey="pv" fill="#8884d8">
                  <LabelList dataKey="name" position="top" />
                </Bar>
              </BarChart>
            </div>
          </div>

          <div className="shadow rouded-md p-4">
            <p className="text-gray-800 text-lg font-semibold">
              Most Used Application Subject
            </p>

            <PieChart width={300} height={200}>
              <Pie
                data={pieData}
                cx={120}
                cy={100}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                label
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    label={entry.name}
                  />
                ))}
              </Pie>
            </PieChart>
            <div className="flex items-center gap-2">
              <div className="bg-blue-400 w-4 h-4">{""}</div>
              <p className="text-gray-500 mt-3">Dhaka</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="bg-[#00C49F] w-4 h-4">{""}</div>
              <p className="text-gray-500 mt-3">Chittagong</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="bg-yellow-400 w-4 h-4">{""}</div>
              <p className="text-gray-500 mt-3">Rajshahi</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="bg-orange-400 w-4 h-4">{""}</div>
              <p className="text-gray-500 mt-3">Mymensingh</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardContentArea;
