import {
  login as fireLogin,
  registration as fireRegistration,
} from "../store/reducer/AuthReducer";

import { store } from "../store/Store";

export class AuthService {
  constructor() {
    this.dispatch = store.dispatch;
  }
  loginWithEmailOrMobile(emailOrMobile, password, partnerId) {
   return this.dispatch(
      fireLogin({
        emailOrMobile: emailOrMobile,
        password: password,
      })
    );
  }
  registrationWithEmailOrMobile(name, mobile, password) {
    this.dispatch(
      fireRegistration({
        name: name,
        mobile: mobile,
        password: password,
      })
    );
  }
}
