import React from "react";
// import Notofication from "../notification/Notofication";
import Search from "../search/Search";
import UserDropdown from "../user/UserDropdown";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="wrapper flex items-center">
      <div className="logoWrapper flex-1">
        <img src="/images/logoColor.svg" className="logo" alt="logo" />
        <Link
          to={{
            pathname: "/",
          }}
          className="no-underline"
        >
          <p className="logoTitle cursor-pointer">আমার সেবা</p>
        </Link>
      </div>
      <div className="searchWrapper flex-1">
        <Search />
      </div>
      <div className="notification flex-1">{/* <Notofication /> */}</div>
      <div className="user flex-1">
        <UserDropdown />
      </div>
    </div>
  );
};

export default Navbar;
