import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import { ForgotPasswordService } from "../../../services/ForgotPasswordService";
// import { AuthService } from "../../../services/AuthService";
import store from '../Store'

const sheba_accountkit_url = process.env.ACCOUNT_URL + '/api/v1/accountkit'
const accountkit_url = process.env.SHEBA_ACCOUNTKIT_END_POINT
const appID = process.env.SHEBA_ACCOUNTKIT_APP_ID

const headers = {
    'Content-Type': 'application/json',
}

const initialState = {
    apiToken: null,
    shootOtpModal: false,
    otpModal: false,
    setPasswordModal: false,
    authorizationCode: null,
    phoneNumber: null
}

export const fetchToken = createAsyncThunk(
    "forgotPassword/fetchToken",
    async () => {
        try {
            const response = await axios.get(sheba_accountkit_url + '/generate/token?app_id=' + appID, null, null)
            return response.data.token
        } catch (error) {
            return
        }
    }
)

export const shootOtp = createAsyncThunk(
    "forgotPassword/shootOtp",
    async (phoneNumber, { rejectWithValue }) => {
        const data = {
            mobile: phoneNumber.mobile
        }
        
        try {
            const response = await axios.post(process.env.API_URL + '/api/v1/shoot-otp', data, headers)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const validateOtp = createAsyncThunk(
    "forgotPassword/validateOtp",
    async (otp, { rejectWithValue }) => {
        const data = {
            otp: otp
        }

        try {
            const response = await axios.post(accountkit_url + '/validate-otp', data, headers)
            return response.data.authorization_code
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const setNewPassword = createAsyncThunk(
    "forgotPassword/setNewPassword",
    async (setPasswordParams, { rejectWithValue }) => {
        const data = {
            new_password: setPasswordParams.newPassword,
            authorization_code: setPasswordParams.authorizationCode,
        }
        try {
            const response = await axios.put(process.env.WEBSTORE_USER + '/api/v1/partners/' + `${setPasswordParams.partnerId}` + '/auth/password/forget', data, headers)
            
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const forgotPasswordReducer = createSlice({
    name: 'forgotPasswordReducer',
    initialState,
    reducers: {
        openForgotPasswordModal: (state) => {
            state.forgotPasswordModal = true
        },
        closeForgotPasswordModal: (state) => {
            state.forgotPasswordModal = false
        },
        openOtpModal: (state) => {
            state.otpModal = true
        },
        closeOtpModal: (state) => {
            state.otpModal = false
        },
        openSetPasswordModal: (state) => {
            state.setPasswordModal = true
        },
        closeSetPasswordModal: (state) => {
            state.setPasswordModal = false
        },
        getPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchToken.fulfilled, (state, action) => {
                state.apiToken = action.payload
            })
            .addCase(shootOtp.fulfilled, (state, action) => {
                state.shootOtpModal = false
                state.otpModal = true
            })
            .addCase(validateOtp.fulfilled, (state, action) => {
            //     state.authorizationCode = action.payload
            //     state.otpModal = false
            //     state.setPasswordModal = true
            })
            .addCase(validateOtp.rejected, (state, action) => {
                // state.authorizationCode = action.payload
                state.otpModal = false
                state.setPasswordModal = true
            })
            .addCase(setNewPassword.fulfilled, (state, action) => {
                state.setPasswordModal = false
            })
    }
})

export const { openForgotPasswordModal, closeForgotPasswordModal, getPhoneNumber, openOtpModal, closeOtpModal,
    openSetPasswordModal, closeSetPasswordModal } = forgotPasswordReducer.actions

// export const selectApiToken = (state) => state.forgotPassword.apiToken
// export const selectShootOtpModal = (state) => state.forgotPassword.shootOtpModal
// export const selectPhoneNumber = (state) => state.forgotPassword.phoneNumber
// export const selectOtpModal = (state) => state.forgotPassword.otpModal
// export const selectSetPasswordModal = (state) => state.forgotPassword.setPasswordModal
// export const selectAuthorizationCode = (state) => state.forgotPassword.authorizationCode

export default forgotPasswordReducer.reducer