import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  news: null,
};

export const getNews = createAsyncThunk(
  "news/getNews",
  async (appointment, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/v1/news",
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createNews = createAsyncThunk(
  "news/createNews",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const date = {
      start: data.startDate,
      end: data.endDate,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          `/v1/applications/appointments/${data.id}/update`,
        date,
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const news = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNews.fulfilled, (state, action) => {
        state.news = action.payload.news;
      })
      .addCase(getNews.rejected, (state, action) => {
        console.log("News get REJECTED");
      })
      .addCase(createNews.fulfilled, (state, action) => {
        window.location.reload();
      })
      .addCase(createNews.rejected, (state, action) => {
        console.log("NEWS POST REJECTED");
      });
  },
});

// export const { clearAuthError } = authReducer.actions

export default news.reducer;
