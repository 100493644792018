import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { useSelector } from "react-redux";
import { LibraryService } from "../../services/LibraryService";
import Modal from "../../components/modals/Modal";
import { addActiveId } from "../../store/reducer/LibraryReducer";
import { useDispatch } from "react-redux";
import BookCreateModal from "../../components/modals/BookCreateModal";

const Librarypage = () => {
  const books = useSelector((state) => state.library.books);
  const dispatch = useDispatch();
  const activeId = useSelector((state) => state.library.activeId);
  const bookInfo = useSelector((state) => state.library.bookDetails);
  const [bookDetails, setBookDetails] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  useEffect(() => {
    setBookDetails(bookInfo);
    setActiveModal(activeId);
  }, [activeId, bookInfo]);

  useEffect(() => {
    const libraryService = new LibraryService();
    libraryService.getBooks();
  }, []);

  //in your component
  const addDefaultSrc = (e) => {
    e.target.src = "./images/default.png";
  };

  const fetchDetails = (id) => {
    const libraryService = new LibraryService();
    libraryService.getBookDetails(id);
    dispatch(addActiveId(id));
  };

  return (
    <Layout>
      <div className="relative p-5 shadow rounded-md border border-gray-100 w-[70%] h-[70%] ml-[4%] mt-[3%]">
        <button
          className="py-2 px-4 bg-green-500 text-white rounded absolute right-[45px]"
          onClick={() => setOpenCreateModal(true)}
        >
          + Upload New Book
        </button>
        <p className="text-gray-500 text-3xl">Book List</p>
        {books && (
          <table class="table-fixed w-full text-left text-gray-500 dark:text-gray-400">
            <thead className="border-b border-t border-gray-900">
              <tr>
                <th className="text-gray-900 p-2">Thumb</th>
                <th className="text-gray-900 p-2">Title</th>
                <th className="text-gray-900 p-2">Details</th>
                <th className="text-gray-900 p-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {books.map((book) => {
                return (
                  <tr className="mb-3" key={book.id}>
                    <td className="text-gray-500 p-2">
                      <img
                        onError={(e) => addDefaultSrc(e)}
                        src={book.image}
                        alt="thumb"
                        className="w-16 h-16 rounded-md border rounded-md"
                      />
                    </td>
                    <td className="text-gray-500 p-2">{book.name}</td>
                    <td className="text-gray-500 p-2">
                      <button
                        className="bg-blue-500 text-white p-2 rounded"
                        onClick={() => fetchDetails(book.id)}
                      >
                        Show Details
                      </button>
                    </td>
                    <td className="text-gray-500 p-2">
                      <button className="py-2 px-4 bg-green-500 text-white rounded-md">
                        Edit
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      {bookDetails && <Modal show={bookDetails.id === activeModal} />}
      <BookCreateModal
        show={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
      />
    </Layout>
  );
};

export default Librarypage;
