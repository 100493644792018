import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useHistory } from 'react-router-dom';

const initialState = {
	token: null,
	registrationError: null,
	loginError: null
};

export const login = createAsyncThunk(
	"auth/login",
	async (loginCredentials, { rejectWithValue }) => {
		const data = {
			mobile: loginCredentials.emailOrMobile,
			password: loginCredentials.password
		};
		try {
			const response = await axios.post(
				process.env.REACT_APP_API_URL + "/v1/login",
				data,
				{
					"Content-Type": "application/json"
				}
			);
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const registration = createAsyncThunk(
	"auth/registration",
	async (registrationCredentials, { rejectWithValue }) => {
		const data = {
			name: registrationCredentials.name,
			mobile: registrationCredentials.mobile,
			password: registrationCredentials.password
		};
		try {
			let response = await axios.post(
				process.env.REACT_APP_API_URL + "/v1/register",
				data,
				{
					"Content-Type": "application/json"
				}
			);
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const auth = createSlice({
	name: "auth",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(login.rejected, (state, action) => {
				state.loginError = action.payload;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.token = action.payload.token;
				localStorage.setItem("amar-sheba-token", action.payload.token);
				window.location.href = "/"
			})
			.addCase(registration.rejected, (state, action) => {
				state.registrationError = action.payload;
			})
			.addCase(registration.fulfilled, (state, action) => {
				state.token = action.payload.token;
				const history = useHistory();
				history.push("/");
			});
	}
});

export default auth.reducer;
