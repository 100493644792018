import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { store } from "./store/Store";
import { Provider } from "react-redux";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // You can log the error here or send it to an error tracking service
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can customize the fallback UI here
      return (
        <div className="w-full h-screen flex justify-center items-center">
          <div class="bg-white shadow-lg rounded-lg px-8 py-6 w-[450px] h-[350px]">
            <div class="text-center">
              <div className='flex items-center justify-center'>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="red"
                  width="50px"
                  height="50px"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm2 14h-4v-2h4v2zm0-4h-4V7h4v5z" />
                </svg>
              </div>

              <h2 class="text-xl font-bold mb-4">Oops! Something Went Wrong</h2>
              <p class="text-gray-500 mb-4">
                We're sorry, but there seems to have been an issue processing
                your request. Please try again later.
              </p>
              <button
                onClick={() => (window.location.href = "/")}
                class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById("root")
);
