import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Appointments from "../pages/appointments";
import DashboardLayout from "../pages/dashboard/index";
import Login from "../pages/login/Login";
// import Registration from "../pages/registration/Registration";
import RoadConstruction from "../pages/road-constructions";
import NewsPage from "../pages/news";
import GeneralApplication from "../pages/generalApplication";
import Allowance from "../pages/allowance";
import Medical from "../pages/medical";
import Stipend from "../pages/stipend";
import Educational from "../pages/educational";
import Electricity from "../pages/electricity";
import PoolCulvert from "../pages/poolCulvert";
import Solar from '../pages/solar'
import Religious from '../pages/religious'
import PublicHealth from '../pages/publicHealth'
import Interview from '../pages/interview'
import Library from '../pages/library'

const Routes = () => {
  const isAuthenticated = localStorage.getItem("amar-sheba-token");
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/login"
          render={() => (!isAuthenticated ? <Login /> : <Redirect to="/" />)}
        />
        <Route
          exact
          path="/"
          render={() =>
            isAuthenticated ? <DashboardLayout /> : <Redirect to="/login" />
          }
        />
        <Route
          exact
          path="/appointments"
          render={() =>
            isAuthenticated ? <Appointments /> : <Redirect to="/login" />
          }
        />
        <Route
          exact
          path="/general-application"
          render={() =>
            isAuthenticated ? <GeneralApplication /> : <Redirect to="/login" />
          }
        />
        <Route
          exact
          path="/road-constructions"
          render={() =>
            isAuthenticated ? <RoadConstruction /> : <Redirect to="/login" />
          }
        />
        <Route
          exact
          path="/allowance"
          render={() =>
            isAuthenticated ? <Allowance /> : <Redirect to="/login" />
          }
        />
        <Route
          exact
          path="/medical"
          render={() =>
            isAuthenticated ? <Medical /> : <Redirect to="/login" />
          }
        />
        <Route
          exact
          path="/stipend"
          render={() =>
            isAuthenticated ? <Stipend /> : <Redirect to="/login" />
          }
        />

        <Route
          exact
          path="/educational"
          render={() =>
            isAuthenticated ? <Educational /> : <Redirect to="/login" />
          }
        />
        <Route
          exact
          path="/electricity"
          render={() =>
            isAuthenticated ? <Electricity /> : <Redirect to="/login" />
          }
        />
        <Route
          exact
          path="/pool-culvert"
          render={() =>
            isAuthenticated ? <PoolCulvert /> : <Redirect to="/login" />
          }
        />
        <Route
          exact
          path="/solar"
          render={() =>
            isAuthenticated ? <Solar /> : <Redirect to="/login" />
          }
        />

        <Route
          exact
          path="/religious"
          render={() =>
            isAuthenticated ? <Religious /> : <Redirect to="/login" />
          }
        />

        <Route
          exact
          path="/public-health"
          render={() =>
            isAuthenticated ? <PublicHealth /> : <Redirect to="/login" />
          }
        />

        <Route
          exact
          path="/interview"
          render={() =>
            isAuthenticated ? <Interview /> : <Redirect to="/login" />
          }
        />

        <Route
          exact
          path="/news"
          render={() =>
            isAuthenticated ? <NewsPage /> : <Redirect to="/login" />
          }
        />
        <Route
          exact
          path="/library"
          render={() =>
            isAuthenticated ? <Library /> : <Redirect to="/login" />
          }
        />
        {/* <Route exact path="/registration" component={Registration} /> */}
      </Switch>
    </Router>
  );
};

export default Routes;
