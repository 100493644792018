import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyPDF from "./MyPDF";

const styles = {
	button: {
		backgroundColor: "#007bff",
		color: "#fff",
		padding: "10px 20px",
		borderRadius: "5px",
		textDecoration: "none",
		cursor: "pointer"
	}
};

const DownloadPDF = ({ details }) => {
	return (
		<PDFDownloadLink
			document={<MyPDF details={details} />}
			fileName="Amar-Seba-Application.pdf"
		>
			{({ blob, url, loading, error }) => (
				<div style={styles.button}>
					{loading ? "Generating PDF..." : "Download PDF"}
				</div>
			)}
		</PDFDownloadLink>
	);
};

export default DownloadPDF;
