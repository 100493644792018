import React, { children } from "react";
import Navbar from "../components/dashboard/Navbar";
import Sidebar from "../components/dashboard/Sidebar";

function Layout({children}) {
	return (
		<>
			<div>
				<Navbar />
			</div>
			<div className="flex">
				<Sidebar />
				{children}
			</div>
		</>
	);
}

export default Layout;
