import React, { useEffect } from "react";
import Layout from "../../layout";
// import { AppoitmentService } from "../../services/Appointment";
// import { useSelector } from "react-redux";
import PublicHealthTable from "../../components/tables/PublicHealthTable";
// import { RoadConstructionService } from "../../services/RoadConstructionService";

const PublicHealth = () => {
  // const applications = useSelector(
  //   (state) => state.roadConstruction.applications
  // );
  useEffect(() => {
    // const roadConstructionService = new RoadConstructionService();
    // roadConstructionService.getRoadConstructionApplication();
  }, []);

  const data = [
    {
      id: 2,
      applicant_name: "Rahim",
      created_at: "27-06-2021",
      work_status: "Pending",
      status: "Accepted",
    },
    {
      id: 3,
      applicant_name: "Karim",
      created_at: "27-06-2021",
      work_status: "Pending",
      status: "Accepted",
    },
    {
      id: 4,
      applicant_name: "Arnab",
      created_at: "27-06-2021",
      work_status: "Pending",
      status: "Accepted",
    },
  ];

  return <Layout>{data && <PublicHealthTable info={data} />}</Layout>;
};

export default PublicHealth;
