import {
  getApplications as fetchApplications,
  applicationUpdate as fireStatusUpdate,
} from "../store/reducer/ApplicationReducer";

import { store } from "../store/Store";

export class ApplicationService {
  constructor() {
    this.dispatch = store.dispatch;
  }
  getApplications() {
    this.dispatch(fetchApplications());
  }

  updateApplicationStatus(id, status) {
    this.dispatch(fireStatusUpdate({ id: id, status: status }));
  }
}
