import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";

const Application = () => {
  const [open, setOpen] = useState(false);
  const optionProperty = [
    {
      title: "সাধারন আবেদন",
      url: "/general-application",
    },
    // {
    //   title: "রাস্তার জন্যে আবেদন",
    //   url: "/road-constructions",
    // },
    // {
    //   title: "বৃদ্ধ ভাতার জন্যে আবেদন",
    //   url: "/allowance",
    // },
    // {
    //   title: "চিকিৎসার জন্যে আবেদন",
    //   url: "/medical",
    // },
    // {
    //   title: "ভাতার জন্যে আবেদন",
    //   url: "/stipend",
    // },
    // {
    //   title: "শিক্ষার জন্য আবেদন ",
    //   url: "/educational",
    // },
    // {
    //   title: "বিদ্যুৎ এর জন্য আবেদন",
    //   url: "/electricity",
    // },
    // {
    //   title: "পুলের/কালভার্টের জন্যে আবেদন",
    //   url: "/pool-culvert",
    // },
    // {
    //   title: "সোলারের জন্য আবেদন",
    //   url: "/solar",
    // },
    // {
    //   title: "ধর্মীয় বিষয়ক আবেদন",
    //   url: "/religious",
    // },
    // {
    //   title: "গণ স্বাস্থ্য বিষয়ক আবেদন",
    //   url: "/public-health",
    // },
    // {
    //   title: "ইন্টারভিউ বিষয়ক আবেদন",
    //   url: "/interview",
    // },
  ];

  const options = (title, url) => {
    return (
      <Link
        to={{
          pathname: `${url}`,
        }}
      >
        <li>{title}</li>
      </Link>
    );
  };
  return (
    <>
      <p
        className="optionTitle"
        onClick={() => setOpen(!open)}
        aria-controls="application-collapse-text"
        aria-expanded={open}
      >
        <span className="iconGap">
          <img src="/images/application.svg" alt="application" />
        </span>
        আবেদন সমূহ
      </p>
      <Collapse in={open}>
        <div id="application-collapse-text" className="application-wrap">
          <ul className="">
            {optionProperty.map((option) => {
              return options(option.title, option.url);
            })}
          </ul>
        </div>
      </Collapse>
    </>
  );
};

export default Application;
