export const textInput = (type, icon, placeholder, name, handleChange) => {
	return (
		<div>
			<input type={type} className="loginInput" placeholder={placeholder} name={name} onChange={(e)=> handleChange(e)}/>
			<span>
				<img className="iconPosition" src={icon} alt="" />
			</span>
		</div>
	);
};

export const checkbox = (type, icon) => {
	return (
		<div id="remember">
			<input type="checkbox" value="lsRememberMe" id="rememberMe" />
			<label for="rememberMe">Remember me</label>
		</div>
	);
};

export const generalCheckbox = (value, label, changeTime) => {
	return (
		<div id="checkbox">
			<input type="radio" value={value} id={value} name="timeSlot" onClick={()=> changeTime(value)}/>
			<label for={value}>{label}</label>
		</div>
	);
};
