import React from "react";
import DashboardContentArea from "../../components/dashboard/DashboardContentArea";
import Layout from "../../layout";

const DashboardLayout = () => {
	return (
		<Layout>
			<DashboardContentArea />
		</Layout>
	);
};

export default DashboardLayout;
