/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { LibraryService } from "../../services/LibraryService";

const BookCreateModal = (props) => {
  const cancelButtonRef = useRef(null);
  const [bookInfo, setBookInfo] = useState({
    name: "",
    category: "history",
    writer: "",
    rating: "",
    publisher: "",
  });

  const handleChange = ({ target }) => {
    setBookInfo({ ...bookInfo, [target.name]: target.value });
  };

  const submitForm = () => {
    const libraryService = new LibraryService();
    libraryService.createBooks(bookInfo);
  };

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => props.setOpenCreateModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Book Information
                    </Dialog.Title>
                    <form onSubmit={() => submitForm()}>
                      <div className="mt-2 flex flex-col items-start">
                        <p className="text-gray-600 mb-1">Name</p>
                        <input
                          name="name"
                          type="text"
                          className="p-2 mb-2 border rounded w-full"
                          placeholder="Book Name"
                          onChange={(e) => handleChange(e)}
                        />
                        <p className="text-gray-600 mb-1">Writer</p>
                        <input
                          name="writer"
                          type="text"
                          className="p-2 mb-2 border rounded w-full"
                          placeholder="Book writer"
                          onChange={(e) => handleChange(e)}
                        />
                        <p className="text-gray-600 mb-1">Publisher</p>
                        <input
                          name="publisher"
                          type="text"
                          className="p-2 mb-2 border rounded w-full"
                          placeholder="Book publisher"
                          onChange={(e) => handleChange(e)}
                        />
                        <p className="text-gray-600 mb-1">Category</p>
                        <input
                          name="category"
                          type="text"
                          className="p-2 mb-2 border rounded w-full"
                          placeholder="Book category"
                          onChange={(e) => handleChange(e)}
                        />
                        <p className="text-gray-600 mb-1">Rating</p>
                        <input
                          name="rating"
                          type="number"
                          className="p-2 mb-5 border rounded w-full"
                          placeholder="Book Rating"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <button className="bg-green-500 py-2 px-8 text-white rounded w-[50%]">
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BookCreateModal;
