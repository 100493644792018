import React, { useState } from "react";
import { Collapse } from "react-bootstrap";

 const Medical = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <p
                className="optionTitle"
                onClick={() => setOpen(!open)}
                aria-controls="application-collapse-text"
                aria-expanded={open}
            >
                <span className='iconGap'>
                    <img className="iconSize" src="/images/medical.svg" alt="application" />
                </span>
                চিকিৎসা সহায়তা
            </p>
            <Collapse in={open}>
                <div
                    id="application-collapse-text"
                    className='application-wrap'
                >
                    <ul className="">
                        <li>আবেদন করুন </li>
                    </ul>
                </div>
            </Collapse>
        </>
    );
};

export default Medical