import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { useDispatch } from "react-redux";
import { getApplicationsFiltered } from "../store/reducer/ApplicationReducer";

const typeList = [
  {
    name: "সকল আবেদন",
    application_type: "",
    application_sub_type: "",
  },
  {
    name: "বৃদ্ধ ভাতা",
    application_type: "allowance",
    application_sub_type: "old_age",
  },
  {
    name: "মাতৃকালীন ভাতা",
    application_type: "allowance",
    application_sub_type: "maternity",
  },
  {
    name: "প্রতিদ্বন্দ্বী ভাতা",
    application_type: "allowance",
    application_sub_type: "disabled_person",
  },
  {
    name: "বিধবা ভাতা",
    application_type: "allowance",
    application_sub_type: "widow",
  },
  {
    name: "চিকিৎসা সহায়তা",
    application_type: "medical_assistance",
    application_sub_type: "default",
  },
  {
    name: "উপবৃতি ভাতা",
    application_type: "stipend",
    application_sub_type: "default",
  },
  {
    name: "স্কুল উন্নয়ন",
    application_type: "educational",
    application_sub_type: "school_development",
  },
  {
    name: "কলেজ উন্নয়ন",
    application_type: "educational",
    application_sub_type: "college_development",
  },
  {
    name: "মাদ্রাসা উন্নয়ন",
    application_type: "educational",
    application_sub_type: "madrasa_development",
  },
  {
    name: "রাস্তা উন্নয়ন",
    application_type: "road",
    application_sub_type: "road_development",
  },
  {
    name: "রাস্তা উন্নয়ন",
    application_type: "road",
    application_sub_type: "road_development",
  },
  {
    name: "বিদ্যুৎ উন্নয়ন",
    application_type: "electricity",
    application_sub_type: "default",
  },
  {
    name: "সোলার উন্নয়ন",
    application_type: "solar",
    application_sub_type: "default",
  },
  {
    name: "মসজিদ উন্নয়ন",
    application_type: "religious",
    application_sub_type: "mosque_development",
  },
  {
    name: "ঈদগাহ উন্নয়ন",
    application_type: "religious",
    application_sub_type: "eidgah_development",
  },
  {
    name: "মন্দির উন্নয়ন",
    application_type: "religious",
    application_sub_type: "temple_development",
  },
  {
    name: "এতিমখানা উন্নয়ন",
    application_type: "religious",
    application_sub_type: "orphanage_development",
  },
  {
    name: "টিউব অয়েল নির্মাণ",
    application_type: "public_health",
    application_sub_type: "tube_well_installation",
  },
  {
    name: "কালভার্ট নির্মাণ",
    application_type: "pool_culvert",
    application_sub_type: "culvert_construction",
  },
  {
    name: "পুল নির্মাণ",
    application_type: "pool_culvert",
    application_sub_type: "pool_construction",
  },
  {
    name: "সাধারণ আবেদন",
    application_type: "general",
    application_sub_type: "default",
  },
];

export default function Example() {
  const [selected, setSelected] = useState(typeList[0]);

  const dispacth = useDispatch();

  const handleFilter = (value) => {
    dispacth(
      getApplicationsFiltered({
        application_type: value.application_type,
        application_sub_type: value.application_sub_type,
      })
    );
  };

  return (
    <div className="w-72">
      <Listbox
        value={selected}
        onChange={(value) => {
          setSelected(value);
          handleFilter(value);
        }}
      >
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full border cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className="absolute mt-1 max-h-60 w-full
            overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1
            ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {typeList.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 w-full ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {person.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
