import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import { useDispatch } from "react-redux";
import {
	applicationUpdate,
	getApplicationsDetails
} from "../../store/reducer/ApplicationReducer";
import DownloadPDF from "../DownloadPdf";

const ApplicationDetailsModal = (props) => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const dispatch = useDispatch();
	const [data, setData] = useState(null);

	useEffect(() => {
		if (show) {
			dispatch(getApplicationsDetails(props.id)).then((res) => {
				if (res.meta.requestStatus === "fulfilled") {
					setData(res.payload.details);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show]);

	return (
		<div id="appointUpdate">
			<Button variant="primary" onClick={handleShow}>
				Details
			</Button>
			<Modal show={show} onHide={handleClose} className="cardWrapper">
				<div
					className="absolute top-[-20px] right-2 cursor-pointer"
					onClick={handleClose}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						fill="red"
						width="48px"
						height="48px"
					>
						<path d="M12 10.585L9.707 8.293a1 1 0 00-1.414 1.414L10.585 12l-2.292 2.293a1 1 0 101.414 1.414L12 13.414l2.293 2.293a1 1 0 001.414-1.414L13.414 12l2.293-2.293a1 1 0 00-1.414-1.414L12 10.586z" />
					</svg>
				</div>
				<div className="flex flex-col w-full">
					<div className="infoTileArea">User Information</div>
					<div className="infowrap">
						<div className="d-flex justify-contetnt-center">
							<div className="userInfoCard relative">
								<div className="row">
									<div className="col-3">
										<p className="paddingZero">Date</p>
										<p className="paddingZero">Mobile No</p>
										<p className="paddingZero">User Name</p>
										<p className="paddingZero">Status</p>
									</div>
									<div className="col-1">
										<p className="paddingZero">:</p>
										<p className="paddingZero">:</p>
										<p className="paddingZero">:</p>
										<p className="paddingZero">:</p>
									</div>
									<div className="col-8">
										<p className="paddingZero">{data?.created_at}</p>
										<p className="paddingZero">{data?.mobile}</p>
										<p className="paddingZero">{data?.name}</p>
										<p className="paddingZero">{data?.status}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="applicationArea">
							<p className="dataText w-[100%]">{data?.details}</p>
						</div>
						<div className="d-flex gap-5 justify-content-center">
							{data?.status === "pending" && (
								<>
									<Button
										variant="success"
										className=""
										onClick={() => {
											dispatch(
												applicationUpdate({ id: props.id, status: "accepted" })
											);
										}}
									>
										Accept
									</Button>
									<Button
										variant="danger"
										onClick={() => {
											dispatch(
												applicationUpdate({ id: props.id, status: "declined" })
											);
										}}
									>
										Decline
									</Button>
								</>
							)}
						</div>
						{data && <DownloadPDF details={data} />}
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default ApplicationDetailsModal;
