import React from "react";
import { UserCircleIcon, DocumentTextIcon, ExclamationCircleIcon, PencilAltIcon } from "@heroicons/react/solid";

const StatisticsCard = (props) => {
  const getIcon = () => {
    switch (props.icon) {
      case "user":
        return <UserCircleIcon className="w-14 h-14 text-blue-800" />;
      case "applications":
        return <DocumentTextIcon className="w-14 h-14 text-green-500" />;
      case "complaints":
        return <ExclamationCircleIcon className="w-14 h-14 text-red-500" />;
      case "appointments":
        return <PencilAltIcon className="w-14 h-14 text-yellow-500" />;
      default:
        break;
    }
  }
  return (
    <div className="border shadow rounded-md bg-white p-2 w-1/4">
      <div className="flex justify-between">
        <div>
          <p className="text-base text-gray-500 capilatize">{props.title}</p>
          <p className="text-2xl text-gray-500 font-bold">{props.data}</p>
        </div>
        <div>{getIcon()}</div>
      </div>
      <div className="h-3 relative max-w-xl rounded-full overflow-hidden mb-1">
        <div className="w-full h-full bg-gray-200 absolute"></div>
        <div
          className={`h-full ${props.color} absolute`}
          style={{ width: "20%" }}
        ></div>
      </div>
      <p className="text-gray-500 text-sm">Previous Month</p>
    </div>
  );
}

export default StatisticsCard;
