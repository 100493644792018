import React from "react";

const Pagination = ({
  limit,
  offset,
  total,
  disablePrev,
  disableNext,
  next,
  prev,
}) => {
  return (
    <div className="flex items-center border-t border-gray-200 bg-white px-6 py-3 w-full rounded-b-lg">
      <div className="flex items-center w-full">
        <div className="w-1/2">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{offset + 1}</span> to{" "}
            <span className="font-medium">{limit}</span> of{" "}
            <span className="font-medium">{total}</span> results
          </p>
        </div>
        <div className="flex w-1/2">
          <div className="flex w-full justify-end">
            <button
              className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white
                px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${
                  disablePrev ? "bg-gray-100 cursor-not-allowed" : ""
                }`}
              disabled={disablePrev}
              onClick={prev}
            >
              Previous
            </button>
            <button
              className={`relative ml-3 inline-flex items-center rounded-md
                  border border-gray-300 bg-white px-4 py-2 text-sm font-medium
                  text-gray-700 hover:bg-gray-50 ${
                    disableNext ? "bg-gray-100 cursor-not-allowed" : ""
                  }`}
              disabled={disableNext}
              onClick={next}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
