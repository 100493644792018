import {
  getBooks as fetchBooks,
  getBookDetails as fetchDetails,
  createBooks as createLibrary,
} from "../store/reducer/LibraryReducer";

import { store } from "../store/Store";

export class LibraryService {
  constructor() {
    this.dispatch = store.dispatch;
  }
  getBooks() {
    this.dispatch(fetchBooks());
  }
  getBookDetails(id) {
    this.dispatch(fetchDetails(id));
  }
  createBooks(data) {
    this.dispatch(createLibrary(data));
  }
}
