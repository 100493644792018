import React, { useState } from "react";
import { Collapse, Button } from "react-bootstrap";

 const Complain = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <p
                className="optionTitle"
                onClick={() => setOpen(!open)}
                aria-controls="application-collapse-text"
                aria-expanded={open}
            >
                <span className='iconGap'>
                    <img src="/images/complain.svg" alt="application" />
                </span>
                অভিযোগ করুন
            </p>
            <Collapse in={open}>
                <div
                    id="application-collapse-text"
                    className='application-wrap'
                >
                    <ul className="">
                        <li>সাধারন আবেদন</li>
                        <li>রাস্তার জন্যে আবেদন</li>
                        <li>পুলের জন্যে আবেদন</li>
                        <li>কালভার্টের জন্যে আবেদন</li>
                        <li>অন্যান্য আবেদন সমূহ</li>
                    </ul>
                </div>
            </Collapse>
        </>
    );
};

export default Complain