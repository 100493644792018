import React, { useState } from "react";
import { AuthService } from "../../services/AuthService";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { textInput } from "../../components/formElements/Forms";
import { Rings } from "react-loader-spinner";

const Login = () => {
	const error = useSelector((state) => state.auth.loginError);
	const [loading, setLoading] = useState(false);

	const [credentials, setCredentials] = useState({
		mobile: null,
		password: null
	});

	const handleChange = ({ target }) => {
		setCredentials({
			...credentials,
			[target.name]: target.value
		});
	};

	const doLogin = (e) => {
		setLoading(true);
		e.preventDefault();
		new AuthService()
			.loginWithEmailOrMobile(credentials.mobile, credentials.password)
			.then((res) => {
				setLoading(false);
			});
	};

	return (
		<div style={{ width: "99vw" }}>
			<form onSubmit={(e) => doLogin(e)}>
				<Row>
					<Col xs={6} md={6} lg={6} className="leftSide">
						<img className="p-5" src="./images/logo.png" alt="logo" />
						<div className="text-center title">আমার সেবা</div>
					</Col>
					<Col xs={6} md={6} lg={6} className="rightSide">
						<div className="loginCard">
							<div className="formWrapper p-3">
								<div className="loginTitle">Login</div>
								<div>
									{textInput(
										"text",
										"./images/mobile-icon.png",
										"Enter Mobile number",
										"mobile",
										handleChange
									)}
									{textInput(
										"password",
										"./images/lock-icon.png",
										"Enter password",
										"password",
										handleChange
									)}
									{/* {checkbox()} */}
									{error && <div className="error">{error.message}</div>}
									<button
										disabled={loading}
										className="loginButton flex text-white items-center justify-center"
										type="submit"
									>
										{loading && (
											<Rings
												height="40"
												width="40"
												color="#fff"
												radius="6"
												wrapperStyle={{}}
												wrapperClass=""
												visible={true}
												ariaLabel="rings-loading"
											/>
										)}
										Login
									</button>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</form>
		</div>
	);
};

export default Login;
