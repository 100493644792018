import {
  getRoadConstructionApplication as fetchRoadConstructionApplication,
  roadConstructionStatusUpdate as fireRoadConstructionStatusUpdate,
} from "../store/reducer/RoadConstructionReducer";

import { store } from "../store/Store";

export class RoadConstructionService {
  constructor() {
    this.dispatch = store.dispatch;
  }
  getRoadConstructionApplication() {
    this.dispatch(fetchRoadConstructionApplication());
  }
  updateStatus(id, status) {
    this.dispatch(fireRoadConstructionStatusUpdate({ id: id, status: status }));
  }

  // createAppointment(id, startDate, endDate) {
  // 	this.dispatch(
  // 		fireCreateRoadConstruction({
  // 			id: id,
  // 			startDate: startDate,
  // 			endDate: endDate
  // 		})
  // 	);
  // }
}
