import React, { useEffect } from "react";
import Layout from "../../layout";
import { AppoitmentService } from "../../services/Appointment";
import { useSelector } from "react-redux";
import InformationTable from "../../components/InformationTable";

const Appointments = () => {
  const appoitments = useSelector((state) => state.appointments);
  useEffect(() => {
    const appoitmentService = new AppoitmentService();
    appoitmentService.getAppointments();
  }, []);

  return (
    <Layout>
      {appoitments.appointments && (
        <InformationTable info={appoitments.appointments} />
      )}
    </Layout>
  );
};

export default Appointments;
