import {
  getNews as fetchNews,
  createNews as fireCreateNews,
} from "../store/reducer/NewsReducer";

import { store } from "../store/Store";

export class NewsService {
  constructor() {
    this.dispatch = store.dispatch;
  }
  getNews() {
    this.dispatch(fetchNews());
  }
  createNews(id, startDate, endDate) {
    this.dispatch(
      fireCreateNews({
        id: id,
        startDate: startDate,
        endDate: endDate,
      })
    );
  }
}
