import React from "react";
import { Table, Button } from "react-bootstrap";
import formatDate from "../helpers/dateFormate";
import { AppoitmentService } from "../services/Appointment";
import AppointmentModal from "./formElements/AppointmentModal";

function InformationTable(props) {
	const appointmentService = new AppoitmentService();
	return (
		<div className="tableWrapper p-4">
			<p className="tableTitle">Appoitment List</p>
			<Table responsive>
				<thead
					className="headerWraper text-center"
					style={{
						borderBottom: "2px solid #888",
						padding: "10px 0"
					}}
				>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Date</th>
						<th>Schedule</th>
						<th>Status</th>
						<th>Accept</th>
						<th>Decline</th>
					</tr>
				</thead>
				<tbody className="text-center">
					{props.info &&
						props.info.map((item) => {
							return (
								<tr>
									<td>{item.id}</td>
									<td>{item.applicant_name}</td>
									<td>{formatDate(item.created_at)}</td>
									<td>{formatDate(item.start)}</td>
									<td>{item.status}</td>
									{item.status === "Pending" ? (
										<td>
											<Button
												variant="success"
												onClick={() =>
													appointmentService.updaStatus(
														item.id,
														"Accepted"
													)
												}
											>
												Accept
											</Button>
										</td>
									) : item.status === "Accepted" || item.status === "Declined" ? (
										<td>
											<AppointmentModal id={item.id} />
										</td>
									) : (
										"--"
									)}
									{item.status === "Pending" ? (
										<td>
											<Button
												variant="danger"
												onClick={() =>
													appointmentService.updaStatus(
														item.id,
														"Declined"
													)
												}
											>
												Decline
											</Button>
										</td>
									) : (
										"--"
									)}
								</tr>
							);
						})}
				</tbody>
			</Table>
		</div>
	);
}

export default InformationTable;
