import React from "react";
import { FaSearch } from "react-icons/fa";

const Search = () => {
	return (
		<>
			<input type="text" className="searchInput" placeholder="search " />
			<span className="searchIcon flex mt-[6px]">
				<FaSearch />
			</span>
		</>
	);
};

export default Search;
