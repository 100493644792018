import React, { useEffect } from "react";
import Layout from "../../layout";
import { useSelector } from "react-redux";
import GeneralApplicationTable from "../../components/tables/GeneralApplicationTable";
import { ApplicationService } from "../../services/ApplicationService";
import {
  selectApplicationLoading,
  selectApplications,
} from "../../store/reducer/ApplicationReducer";
import { TailSpin } from "react-loader-spinner";

const RoadConstruction = () => {
  const applications = useSelector(selectApplications);
  const applicationLoading = useSelector(selectApplicationLoading);
  useEffect(() => {
    const appicationService = new ApplicationService();
    appicationService.getApplications();
  }, []);

  return (
    <Layout>
      {!applicationLoading && applications && (
        <GeneralApplicationTable info={applications} />
      )}
      {applicationLoading && (
        <div className="w-full h-screen flex justify-center items-center mt-[-100px]">
          <TailSpin type="Rings" color="#00BFFF" height={60} width={60} />
        </div>
      )}
    </Layout>
  );
};

export default RoadConstruction;
