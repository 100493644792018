import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	applications: null,
};

export const getApplication = createAsyncThunk(
  "roadConstruction/getApplication",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("amar-sheba-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/v1/applications/road-constructions",
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRoadConstructionApplication = createAsyncThunk(
	"roadConstruction/getRoadConstructionApplication",
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem("amar-sheba-token")
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
		try {
			const response = await axios.get(
				process.env.REACT_APP_API_URL + "/v1/applications/road-constructions",
				config
			);
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const roadConstructionStatusUpdate = createAsyncThunk(
	"roadConstruction/roadConstructionStatusUpdate",
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem("amar-sheba-token")
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
		try {
			const response = await axios.post(
				process.env.REACT_APP_API_URL + `/v1/applications/road-constructions/${data.id}/status-update`,{status:data.status},
				config
			);
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const createRoadConstruction = createAsyncThunk(
	"appointments/createAppointment",
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem("amar-sheba-token")
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
		try {
			const response = await axios.post(
				process.env.REACT_APP_API_URL + `/v1/applications/road-constructions`,
				config
			);
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const roadConstruction = createSlice({
	name: "roadConstruction",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getRoadConstructionApplication.fulfilled, (state, action) => {
				state.applications = action.payload.applications;
			})
            .addCase(getRoadConstructionApplication.rejected, (state, action) => {
				console.log("Road Construction Application Rejected")
			})
			.addCase(roadConstructionStatusUpdate.fulfilled, (state, action) => {
				window.location.reload();
			})
            .addCase(roadConstructionStatusUpdate.rejected, (state, action) => {
				console.log("Road Construction Update Rejected")
			})
			.addCase(createRoadConstruction.fulfilled, (state, action) => {
				window.location.reload();
			})
            .addCase(createRoadConstruction.rejected, (state, action) => {
				console.log("Road Construction Update Rejected")
			})
	}
});



// export const { clearAuthError } = authReducer.actions


export default roadConstruction.reducer;
