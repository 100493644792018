import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BookOpenIcon } from "@heroicons/react/solid";

const News = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Link
        to={{
          pathname: "/news",
        }}
      >
        <p
          className="optionTitle"
          onClick={() => setOpen(!open)}
          aria-controls="application-collapse-text"
          aria-expanded={open}
        >
          <span className="iconGap">
            <BookOpenIcon className="iconSize" />
          </span>
          সংবাদ
        </p>
      </Link>
    </>
  );
};

export default News;
